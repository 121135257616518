<template>
    <b-sidebar
      id="edit-unit-sidebar"
      :visible="isEditUnitSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="(val) => $emit('update:is-edit-unit-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Edit Unit
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />

        </div>

        <!-- BODY -->
        <validation-observer
              ref="refFormObserver"
              #default="{invalid}"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="submitEdit"
          >
            <!-- Kota -->
            <validation-provider
              #default="validationContext"
              name="Unit Name"
              rules="required"
            >
              <b-form-group
                label="Unit Name"
                label-for="Unit Name"
              >
                <b-form-input
                  id="UnitName"
                  v-model="UnitName"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Unit Name"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
             <!-- Unit Type -->
            <validation-provider
              #default="validationContext"
              name="Unit Type"
              rules="required"
            >
              <b-form-group
                label="Unit Type"
                label-for="UnitType"
              >
                <b-form-input
                  id="UnitType"
                  v-model="UnitType"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Unit Type"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                  block
                  :disabled="invalid"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
              @click="hideSidebar()"
              >
                Cancel
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </template>

  <script>
  import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, alphaNum } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import axios from 'axios'
  import { ref } from '@vue/composition-api'
  import moment from 'moment'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  // import useUnitsList from './useUnitsList'

  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BButton,
      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'isEditUnitSidebarActive',
      event: 'update:is-edit-unit-sidebar-active',
    },
    props: {
      isEditUnitSidebarActive: {
        type: Boolean,
        required: true,
      },
      UnitName: {
        type: String,
        required: true,
      },
      UnitType: {
        type: String,
        required: true,
      },
      UnitId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        required,
        alphaNum,
      }
    },
    setup() {
      const blankUnitData = {
        UnitName: '',
        UnitType: '',
      }
      const unitData = ref(blankUnitData)
      const resetunitData = () => {
        unitData.value = blankUnitData
      }

      const onSubmit = () => {
      }

      const {
        refFormObserver,
        getValidationState,
        resetForm,
      } = formValidation(resetunitData)

      return {
        refFormObserver,
        onSubmit,
        getValidationState,
        resetForm,
      }
    },
    mounted() {
          const data = this.$cookies.get('unitEdit')
          this.UnitName = data.unit_name
          this.UnitType = data.unit_type
          this.UnitId = data.unit_id
    },
    methods: {
      hideSidebar() {
        this.isEditUnitSidebarActive = false
      },
      submitEdit() {
        const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
          const payload = {
            unit_id: this.UnitId,
            unit_name: this.UnitName,
              unit_type: this.UnitType,
              time: moment(),
            }
            axios
            .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EDIT_UNIT}`, payload, { headers })
            .then(response => {
                // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
            if (response.data.success === true) {
                  this.$root.$emit('refreshTable', 'refreshTable')
                  this.isEditUnitSidebarActive = false
                  this.UnitName = ""
                  this.UnitType = ""
                  // this.$parent.refUnitListTable.refresh()
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Congratulation',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Your data has been inserted!',
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Notification',
                      icon: 'BellIcon',
                      variant: 'danger',
                      text: 'Something went wrong',
                    },
                  })
                }
                }).catch(error => {
                  console.log(error)
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Notification',
                      icon: 'BellIcon',
                      variant: 'danger',
                      text: 'Something went wrong',
                    },
                  })
                })
      },
    },
  }
  </script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  #add-new-unit-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>
